import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
@Component({
  selector: 'rcp-tab-filter',
  standalone: true,
  imports: [CommonModule, SelectButtonModule, FormsModule],
  templateUrl: './tab-filter.component.html',
  styleUrl: './tab-filter.component.scss',
})
export class TabFilterComponent implements OnInit {
  @Output() selectButtonValueEvent = new EventEmitter<string>();
  @Input() selectedOption:string;
  options!: any[];
  // selectedOption: string = 'monthly';
  isDoubleClickOption: boolean = false;
  addHighlightClass: string;
  ngOnInit() {
     if(!this.selectedOption){
      this.selectedOption = 'monthly'
     } 
    this.options = [
      { label: 'Monthly', value: 'monthly' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Daily', value: 'daily' },
    ];
  }
  clickOnOption(event: string) {
    if (event) {
      this.selectedOption = event;
      this.isDoubleClickOption = false;
      this.selectButtonValueEvent.emit(event);
    } else if (event == null) {
      this.isDoubleClickOption = true;
      this.addHighlightClass = 'highlight-outer-' + this.selectedOption;
    }
  }
}
