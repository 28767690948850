<div class="selectButton">
  <p-selectButton
    [options]="options"
    [ngModel]="selectedOption"
    optionLabel="label"
    optionValue="value"
    (ngModelChange)="clickOnOption($event)"
    [ngClass]="isDoubleClickOption === true ? addHighlightClass : ''"
  />
</div>
